<template>
  <!-- question -->
  <div class="question custom-radio">
    <p>{{ title }}</p>
    <div class="inline-radios">
      <div>
        <input
          type="radio"
          v-model="checked"
          :value="true"
          :name="uuid"
          :id="`${uuid}_1`"
        />
        <label :for="`${uuid}_1`">Sim</label>
      </div>

      <div>
        <input
          type="radio"
          v-model="checked"
          :value="false"
          :name="uuid"
          :id="`${uuid}_2`"
        />
        <label :for="`${uuid}_2`">Não</label>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 } from 'uuid'
export default {
  data: () => ({
    uuid: v4()
  }),
  props: ['title', 'value'],
  computed: {
    checked: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
.question {
  margin-bottom: 3rem;
}
.question p {
  font-weight: bold;
  margin: 15px 0;
  color: #333;
  font-size: 18px;
}

.inline-radios {
  display: flex;
}
.inline-radios div {
  margin-right: 2rem;
}
.custom-radio input[type='radio'] {
  display: none;
  /* Esconde os inputs */
}
.custom-radio label {
  cursor: pointer;
}
.custom-radio input[type='radio'] + label:before {
  border: 1px solid #ccc;
  content: '\00a0';
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 20px;
  margin: -1px 0.8rem 0 0em;
  padding: 3px 0;
  vertical-align: top;
  width: 20px;
  border-radius: 50px;
}
.custom-radio input[type='radio']:checked + label:before {
  background: #05b446;
  color: #fff;
  content: '\2713';
  text-align: center;
}
.custom-radio input[type='radio']:checked + label:after {
  font-weight: bold;
}
</style>
